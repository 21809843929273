@import 'variables';

.ovr-tooltip {
    width: 0;
    height: 0;
    z-index: 500;
    transform: scale(0.9);
    position: fixed;
    background: none;

    &.shown {
        & > .ovr-tooltip-container {
            opacity: 1;
        }

        transform: scale(1);
    }

    &-container {
        opacity: 0;
        z-index: 1;
        overflow: hidden;
        border: none;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0.4em 0.3em;
        color: white;
        font-size: smaller;
        font-weight: 600;
        white-space: normal;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: max-content;
        max-width: 24em;
    }
}
