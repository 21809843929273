.mapboxgl-marker {
}

.mtp-map-marker {
    --tx: 0;
    --ty: 0;
    --scale: 1;
    --deg: 0deg;

    position: relative;
    transform: translate3d(var(--tx), var(--ty), 0) scale(var(--scale)) rotate(var(--deg));
    transition: all 0.15s ease;

    &.focused {
        --scale: 1.5;
    }

    &.blurred {
        opacity: 0.5;
    }

    /* Note: The positioning and sizing of the marker components are
    related to the values set in the their MapLayerControlle. Updating them
    here requires updating them in there as well, and vice-versa. */
    &.mtp-map-asset-marker {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #4caf50;
    }

    &.mtp-map-location-selection-marker,
    &.mtp-map-issue-marker,
    &.mtp-map-task-marker {
        width: 27px;
        height: 37px;
        position: relative;

        [class^='icon-'],
        [class*=' icon-'] {
            font-size: 24px;
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-3px);
        }
    }

    &.mtp-map-issue-marker {
        .mtp-marker-icon-fill {
            fill: #f44336;
        }
    }

    &.mtp-map-task-marker {
        .mtp-marker-icon-fill {
            fill: #ffc238;
        }
    }

    &.mtp-map-location-selection-marker {
        .mtp-marker-icon-fill {
            fill: #2196f3;
        }
    }

    &.mtp-map-poi-marker {
        $dotWidth: 8px;
        $dotHeight: 8px;
        $iconWidth: 32px;
        $iconHeight: 32px;
        $labelWidth: 100px;
        $labelHeight: 40px;

        position: relative;

        &.mode-full {
            width: $labelWidth;
            height: $iconHeight + $labelHeight * 2;
        }

        &.mode-icon_only {
            width: $iconWidth;
            height: $iconHeight;
        }

        &.mode-dot_only {
            width: $dotWidth;
            height: $dotHeight;
        }

        .icon {
            width: $iconWidth;
            height: $iconHeight;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(#{$iconWidth / -2}, #{$iconHeight / -2});
            border-radius: ($iconWidth + $iconHeight) / 4;
            background-color: black;
            color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            > [class^='icon-'] {
                font-size: 24px;
                transform: translateY(1px);
            }
        }

        .label {
            width: $labelWidth;
            height: $labelHeight;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            font-family: 'Arial Rounded MT Bold', 'Arial Rounded MT', 'Helvetica Rounded', Arial, sans-serif;
            font-weight: normal;
            max-lines: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }

        .dot {
            width: $dotWidth;
            height: $dotHeight;
            border-radius: ($dotWidth + $dotHeight) / 4;
            background-color: black;
            color: white;
        }
    }
}
