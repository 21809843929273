@mixin baseTheme() {
    --color-brand-primary: #00385f;
    --color-brand-primary-dark-5: #002947;
    --color-brand-primary-dark-10: #001b2e;
    --color-on-brand-primary: white;
    --color-brand-secondary: #27a6ff;
    --color-brand-secondary-dark-5: #0f9bff;
    --color-brand-secondary-dark-10: #008ff5;
    --color-on-brand-secondary: white;
    --color-text-shadow: black;
}

body {
    @include baseTheme();
}
