@font-face {
    font-family: 'asito_icons_v102';
    src: url('fonts/asito_icons_v102.eot?p51pmm');
    src: url('fonts/asito_icons_v102.eot?p51pmm#iefix') format('embedded-opentype'),
        url('fonts/asito_icons_v102.ttf?p51pmm') format('truetype'),
        url('fonts/asito_icons_v102.woff?p51pmm') format('woff'),
        url('fonts/asito_icons_v102.svg?p51pmm#asito_icons_v102') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'asito_icons_v102' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: '\e933';
}
.icon-gates:before {
    content: '\e920';
}
.icon-atm:before {
    content: '\e921';
}
.icon-bagage-belt:before {
    content: '\e922';
}
.icon-bank:before {
    content: '\e923';
}
.icon-casino:before {
    content: '\e924';
}
.icon-checkin:before {
    content: '\e925';
}
.icon-customs:before {
    content: '\e926';
}
.icon-elevator:before {
    content: '\e927';
}
.icon-escalator:before {
    content: '\e928';
}
.icon-gates1:before {
    content: '\e929';
}
.icon-hotel:before {
    content: '\e92a';
}
.icon-information:before {
    content: '\e92b';
}
.icon-local-shop:before {
    content: '\e92c';
}
.icon-passport:before {
    content: '\e92d';
}
.icon-security-check:before {
    content: '\e92e';
}
.icon-shopping:before {
    content: '\e92f';
}
.icon-stairs:before {
    content: '\e930';
}
.icon-toilets:before {
    content: '\e931';
}
.icon-transfer:before {
    content: '\e932';
}
.icon-flag:before {
    content: '\e91f';
}
.icon-bathroom:before {
    content: '\e900';
}
.icon-broom:before {
    content: '\e901';
}
.icon-cake:before {
    content: '\e902';
}
.icon-chair:before {
    content: '\e903';
}
.icon-cigarette:before {
    content: '\e904';
}
.icon-coffee-streaming:before {
    content: '\e905';
}
.icon-coffee:before {
    content: '\e906';
}
.icon-door:before {
    content: '\e907';
}
.icon-drink-dropped:before {
    content: '\e908';
}
.icon-drinks:before {
    content: '\e909';
}
.icon-fire-extinguisher:before {
    content: '\e90a';
}
.icon-food:before {
    content: '\e90b';
}
.icon-footsteps:before {
    content: '\e90c';
}
.icon-gate-clean:before {
    content: '\e90d';
}
.icon-hamburger:before {
    content: '\e90e';
}
.icon-hammer:before {
    content: '\e90f';
}
.icon-hand-dryer:before {
    content: '\e910';
}
.icon-lamp:before {
    content: '\e911';
}
.icon-liqour-dropped:before {
    content: '\e912';
}
.icon-paper-towel:before {
    content: '\e913';
}
.icon-plug:before {
    content: '\e914';
}
.icon-screen:before {
    content: '\e915';
}
.icon-shower:before {
    content: '\e916';
}
.icon-soap:before {
    content: '\e917';
}
.icon-spray:before {
    content: '\e918';
}
.icon-toilet:before {
    content: '\e919';
}
.icon-trashcan:before {
    content: '\e91a';
}
.icon-vomit:before {
    content: '\e91b';
}
.icon-water:before {
    content: '\e91c';
}
.icon-wheelchair:before {
    content: '\e91d';
}
.icon-wrench:before {
    content: '\e91e';
}
