@forward 'variables';
@use 'variables';
@use 'materials';
@use 'shadows';

@mixin absolute-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

#preload-view {
    $spinner-size: 42px;
    $spinner-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: var(--color-brand-primary);

    .preload-anchor {
        position: relative;
    }

    #preload-container {
        position: absolute;
        top: 0;
        left: -50vw;
        width: 100vw;
        margin: auto;
        transform-origin: top;
        transition: transform 0.26s ease;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        padding: 24px;

        button.btn {
            margin: 24px;
            max-width: 200px;
        }
    }

    .spinner {
        font-size: $spinner-size;
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;

        &.center {
            @include absolute-center;
        }
    }

    .spinner-blade {
        position: absolute;
        left: 0.4629em;
        bottom: 0;
        width: 0.074em;
        height: 0.2777em;
        border-radius: 0.5em;
        background-color: transparent;
        transform-origin: center -0.2222em;
        animation: spinner-fade-preload 1s infinite linear;

        $animation-delay: 0s;
        $blade-rotation: 0deg;

        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
                animation-delay: $animation-delay;
                transform: rotate($blade-rotation);
                $blade-rotation: $blade-rotation + 30;
                $animation-delay: $animation-delay + 0.083;
            }
        }
    }

    @keyframes spinner-fade-preload {
        0% {
            background-color: $spinner-color;
        }

        100% {
            background-color: transparent;
        }
    }

    .btn-preload-reload {
        @include materials.matPlainCard();
        border-radius: 4px;
        transition: all 0.15s ease;
        font-weight: 600;
        font-size: 14px;
        outline: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        position: relative;
        min-height: 35px;
        padding: 5px 12px;
        background-color: var(--color-brand-secondary);
        color: var(--color-on-brand-secondary);
        cursor: pointer;

        &:hover {
            box-shadow: shadows.$shadow-1;
            transform: translateY(-2px);
        }

        &:active {
            transform: translateY(0);
        }

        border: none;

        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        & > i {
            font-size: 18px;
            margin-right: 6px;
        }
    }
}
