@use '@angular/material' as mat;
@use 'variables';
@import '@angular/material';
@import 'source-sans-pro/source-sans-pro.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import 'theme';
@import 'markers';
@import 'map';
@import 'vendors';
@import 'preload-view';
@import 'styles/tooltips';
@include mat.core();
$custom-typography: mat.m2-define-typography-config(
    $font-family: (
        'Source Sans Pro',
        sans-serif,
    ),
);
$custom-theme: mat.m2-define-light-theme(
    (
        typography: $custom-typography,
    )
);
@include mat.all-component-themes($custom-theme);

html,
body {
    margin: 0;
    position: fixed;
    overflow: hidden;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
}

code {
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    padding: 1em;
    border: 1px solid variables.$color-gray-2;
    border-radius: 2px;
    background-color: variables.$color-gray-1;
    display: block;
    user-select: all;
}
